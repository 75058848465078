import React from "react";

import { ErrorBoundary } from "common/utils/errorBoundary";
import { Container } from "./Calendar.styles";
import { AllEntries, Props } from "./AllEntries";
import { useSelector } from "store";
import OldContainer from "hcp/components/CalendarEntries/CalendarEntriesContainer";

const CalendarEntriesContainer = (props: Required<Props>) => {
  const newCalendarView = useSelector(
    ({ user }) => user.features.new_calendar_view,
  );

  if (!newCalendarView) {
    return <OldContainer {...props} />;
  }

  return (
    <Container>
      <ErrorBoundary>
        <AllEntries {...props} />
      </ErrorBoundary>
    </Container>
  );
};

export default CalendarEntriesContainer;
