import React, { useEffect } from "react";
import { injectIntl, IntlShape } from "react-intl";
import {
  CustomModal,
  CustomModalExtras,
} from "@netmedi/frontend-design-system";
import { useDispatch } from "react-redux";
import { setFlag } from "shared/actions/app";

export type Props = CustomModalExtras.BaseProps & {
  ariaDescriptionId?: string;
} & ({ ariaLabel: string } | { ariaLabelId: string });

const AccessibleModal = (props: Props & { intl: IntlShape }) => {
  const ariaLabel = "ariaLabel" in props ? props.ariaLabel : undefined;
  const ariaLabelId = "ariaLabelId" in props ? props.ariaLabelId : undefined;
  const computedAriaLabel = ariaLabelId
    ? props.intl.formatMessage({ id: ariaLabelId })
    : ariaLabel;
  const computedAriaDescription = props.ariaDescriptionId
    ? props.intl.formatMessage({ id: props.ariaDescriptionId })
    : props.ariaDescription;
  // modalOpened is introduced to fix an iOS-specific issue where the bottom navbar remained visible while the modal dialog was open
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFlag("modalOpened", true));
    return () => {
      dispatch(setFlag("modalOpened", false));
    };
  }, []);

  return (
    <CustomModal
      {...props}
      ariaLabel={computedAriaLabel}
      ariaDescription={computedAriaDescription}
    />
  );
};

export default injectIntl(AccessibleModal) as React.FC<Props>;
