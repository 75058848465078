import React from "react";
import CalendarEntries from "client/components/WelcomeClient/CalendarEntries";
import { Container, Header } from "./CalendarEntriesContainer.styles";
import { FormattedMessage } from "react-intl";
import { Id } from "common/utils/types";
import { ErrorBoundary } from "common/utils/errorBoundary";

type Props = {
  clientId?: Id;
};

const CalendarEntriesContainer = ({ clientId }: Props) => {
  return (
    <Container>
      <Header>
        <FormattedMessage id="client.calendar.next" />
      </Header>
      <ErrorBoundary>
        <CalendarEntries clientId={clientId} />
      </ErrorBoundary>
    </Container>
  );
};

export default CalendarEntriesContainer;
