import { stringify } from "qs";
import { Id } from "./types";
import { Filters } from "hcp/components/ProgramDataInsights";

const ISSUE_PAGE_SIZE = 10;

export const endpoints = {
  admin: {
    status: {
      keycloak: "/rest/admin/status/keycloak",
    },
  },
  changeDomain: `/rest/change_domain`,
  failedInvites: {
    index: (filters: Record<string, unknown>) =>
      `/rest/failed_invites?${stringify(filters)}`,
    csv: (filters: Record<string, unknown>) =>
      `/rest/failed_invites.csv?${stringify(filters)}`,
  },
  appointments: (
    id: Id,
    params?: Record<string, number | string | undefined>,
  ) => `/rest/calendars/${id}/appointments?${stringify(params)}`,
  healthAuth: (serviceName: string) => `/health-auth?service=${serviceName}`,
  user: `/rest/user`,
  teams: {
    create: `/rest/hcp_team`,
    delete: (team_id: Id) => `/rest/hcp_team/delete/${team_id}`,
    index: "/rest/hcp_team#index",
    update: (team_id: Id) => `/rest/hcp_team/${team_id}`,
  },
  users: {
    index: (filters: Record<string, unknown>) =>
      `/rest/users?${stringify(filters)}`,
    create: "/rest/users",
    user: (id: Id) => `/rest/users/${id}`,
    allConsents: (id: Id) => `/rest/users/${id}/person_consents`,
    sendMobileAppSms: `/rest/users/send_mobile_app_sms`,
    sendMobileAppEmail: `/rest/users/send_mobile_app_email`,
    reset: (id: Id) => `/rest/users/${id}/reset`,
    validationRules: `/rest/users/user_validation_rules`,
  },
  patient_advisory_board: {
    form_url: (id: Id) =>
      `/rest/users/${id}/person_consents/patient_advisory_board_form_url`,
  },
  population_dashboard_users: (filters: Record<string, unknown>) =>
    `/rest/population_dashboard/clients?${stringify(filters)}`,
  population_dashboard_data_points: (params: Record<string, unknown>) =>
    `/rest/population_dashboard/data_points?${stringify(params)}`,
  tasks: (id?: Id) => `/rest/tasks/${id || ""}`,
  dismiss_task: (id: Id) => `/rest/tasks/${id}/dismiss`,
  task_actions: `/rest/task_actions`,
  invites: {
    show: (id: Id) => `/rest/invites/${id}`,
    create: `/rest/invites/`,
  },
  caregiver_invites: {
    create: `/rest/caregiver_invites/`,
    destroy: (id: Id) => `/rest/caregiver_invites/${id}`,
  },
  client: {
    staff: (id: Id, own = true) =>
      `/rest/clients/${id}/staff?own=${own.toString()}`,
    answers: (client_id: Id, answerId?: Id) =>
      `/rest/clients/${client_id}/answers/${answerId || ""}`,
    graphs: (id: Id, field?: Id) => `/rest/clients/${id}/graphs/${field || ""}`,
    symptoms: (id: Id, params: Record<string, unknown>) =>
      `/rest/clients/${id}/symptoms?${stringify(params)}`,
    allSymptoms: (id: Id, params: Record<string, unknown> = {}) =>
      `/rest/clients/${id}/symptoms/all?${stringify(params)}`,
    symptomComparisonQuestions: (id: Id) =>
      `/rest/clients/${id}/symptoms/symptom_comparison_questions`,
    symptomComparisonAnswers: (id: Id) =>
      `/rest/clients/${id}/symptoms/symptom_comparison_data`,
    treatment_programs: (id: Id) =>
      `/rest/clients/${id}/own_treatment_programs`,
    observations: (id: Id, params: Record<string, unknown>) =>
      `/api/v1/mobile/clients/${id}/observations?${stringify(params)}`,
    taskSubscriptions: {
      index: (client_id: Id) => `/rest/clients/${client_id}/task_subscriptions`,
      update: (client_id: Id) =>
        `/rest/clients/${client_id}/task_subscriptions`,
    },
    caregivers: {
      index: (client_id: Id) => `/rest/clients/${client_id}/caregivers`,
      add: (client_id: Id) => `/rest/clients/${client_id}/caregivers/`,
      removeMultiple: (client_id: Id) =>
        `/rest/clients/${client_id}/caregivers`,
      activate: (client_id: Id, cg_id: Id) =>
        `/rest/clients/${client_id}/caregivers/${cg_id}/activate`,
      decline: (client_id: Id, cg_id: Id) =>
        `/rest/clients/${client_id}/caregivers/${cg_id}/decline`,
    },
    categoryData: (clientId: Id, category: Id) =>
      `/clients/${clientId}/input_form_answers/category/${category}/graph.json`,
  },
  caregivees: {
    index: `/rest/caregivees`,
    signedInUser: `/rest/caregivees/signed_in_user`,
    resetRememberMe: `/rest/caregivees/reset_remember_me`,
    switchAccount: (personId: Id, rememberProfileId: Id | null) => {
      if (rememberProfileId) {
        return `/rest/caregivees/switch_account/${personId}?remember_selected_profile_id=${rememberProfileId}`;
      }
      return `/rest/caregivees/switch_account/${personId}`;
    },
  },
  absences: {
    index: `/rest/absences`,
    show: (id: Id) => `/rest/absences/${id}`,
    create: `/rest/absences`,
    update: (id: Id) => `/rest/absences/${id}`,
    delete: (id: Id) => `/rest/absences/${id}`,
  },
  clients_symptoms_meta: `/rest/clients_symptoms_meta/`,
  inputForm: (id: Id) => `/rest/input_form_answers/${id}/input_form`,
  issues: {
    assign: (id: Id) => `/rest/issues/${id}/assignment`,
    done: (id: Id) => `/rest/issues/${id}/done`,
    done_with_action: (id: Id) => `/rest/issues/${id}/done_with_action`,
    index: (params: Record<string, unknown>) => {
      const stringifiedQueryParams = stringify(
        {
          sort: "updated_at:desc",
          "page[size]": ISSUE_PAGE_SIZE,
          ...params,
        },
        {
          arrayFormat: "brackets",
          addQueryPrefix: true,
        },
      );

      return `/rest/issues${stringifiedQueryParams}`;
    },
    stale: {
      index: `/rest/issues/stale`,
      done: `/rest/issues/stale/done`,
    },
  },
  events: {
    index: (clientId: Id, page = 1) =>
      `/rest/clients/${clientId}/events?${stringify({ page })}`,
  },
  crc_issues: {
    index: (id: Id) => `/rest/clients/${id}/crc_module/crc_issue`,
    done_with_sms: (issueId: Id, clientId: Id) =>
      `/rest/clients/${clientId}/crc_module/crc_issue/${issueId}/done_with_sms`,
  },
  articles: {
    index: "/rest/articles",
    articleWithCategoryMatchAllowed: (id: Id) =>
      `/rest/articles/${id}/category_id_match`,
  },
  shared_documents: {
    show: (clientId?: Id) =>
      `/rest/shared_documents${clientId ? `?client_id=${clientId}` : ""}`,
    index: (id: Id) => `rest/shared_documents/${id}`,
    create: "/rest/shared_documents",
    sent_by_client: (clientId: Id) =>
      `/rest/shared_documents/sent_documents?client_id=${clientId}`,
  },
  data_points: (ifa_id: Id) => `/rest/input_form_answers/${ifa_id}/data_points`,
  section: (ifa_id: Id, section_index: Id) =>
    `/rest/input_form_answers/${ifa_id}/sections/${section_index}`,
  attachment: (ifa_id: Id, data_point_id: Id, attachment_id: Id) =>
    `/rest/input_form_answers/${ifa_id}/data_points/${data_point_id}/attachment/${attachment_id}`,
  staff: "/rest/staff",
  avatar: (id: Id) => `/people/${id}/avatar`,
  update_user_status: (id: Id) => `/people/${id}/update_user_status`,
  conversations: {
    index: (params: Record<string, unknown> = {}) =>
      `/rest/conversations?${stringify(params)}`,
    show: (id: Id) => `/rest/conversations/${id}`,
    participants: (conv: Id, person?: Id) =>
      `/rest/conversations/${conv}/participants/${person || ""}`,
    recent: "/rest/conversations/recent",
  },
  article_string: `/rest/instructions/article_string`,
  instructions: "/rest/instructions",
  symptom_name: "/rest/instructions/symptom_name",
  score_name: "/rest/instructions/score_name",
  feedbacks_with_form_id: "/rest/instructions/feedbacks_with_form_id",
  generic_symptom_feedback: "/rest/instructions/generic_symptom_feedback",
  feedback: (client: Id, answer_id: Id) =>
    `/rest/clients/${client}/answers/${answer_id}/feedback`,
  messages: (conversation: Id, message?: Id) =>
    `/rest/conversations/${conversation}/messages/${message || ""}`,
  massNotifications: {
    index: (
      params: { query?: string; page?: { number: number; size?: number } } = {},
    ) => `/rest/mass_notifications/?${stringify(params)}`,
    create: `/rest/mass_notifications/`,
    show: (messageId: Id) => `/rest/mass_notifications/${messageId}`,
    countRecipients: (params: {
      domains: string[];
      treatment_program_ids?: Id[];
      user_type: string;
      include_clients_with_no_programs?: boolean;
    }) =>
      `/rest/mass_notifications/count_recipients?${stringify(params, {
        indices: false,
        arrayFormat: "brackets",
      })}`,
  },
  sendMessage: (conversation: Id) =>
    `/rest/conversations/${conversation}/messages/send_draft`,
  draft: (conversation: Id) =>
    `/rest/conversations/${conversation}/messages/draft`,
  draftAttachment: (conversation: Id, attachmentId?: Id) =>
    `/rest/conversations/${conversation}/messages/draft_attachment${
      attachmentId ? `/${attachmentId}` : ""
    }`,
  draftAttachments: (conversation: Id, attachmentId?: Id) =>
    `/rest/conversations/${conversation}/messages/draft_attachments${
      attachmentId ? `/${attachmentId}` : ""
    }`,
  updateStaff: (client_id: Id, staff_id: Id = "") =>
    `/rest/clients/${client_id}/staff/${staff_id}`,
  removeStaff: (client_id: Id, staff_id: Id = "") =>
    `/rest/clients/${client_id}/staff/${staff_id}`,
  inputFormAnswers: {
    section: (ifa_id: Id, idx: Id) =>
      `/rest/input_form_answers/${ifa_id}/sections/${idx}/`,
  },
  tracking: "/rest/tracking",
  trackingBeacon: "/rest/tracking_beacon",
  alert_conditions: {
    index: ({ client_id }: { client_id: Id }) =>
      `/rest/clients/${client_id}/alert_conditions`,
    create: ({ client_id }: { client_id: Id }) =>
      `/rest/clients/${client_id}/alert_conditions`,
    update: ({ client_id, id }: { client_id: Id; id: Id }) =>
      `/rest/clients/${client_id}/alert_conditions/${id}`,
    destroy: ({ client_id, id }: { client_id: Id; id: Id }) =>
      `/rest/clients/${client_id}/alert_conditions/${id}`,
  },
  consents: {
    index: "/rest/consents",
    forUser: (id: Id) => `/rest/consents/?person_id=${id}`,
    show: (id: Id) => `/rest/consents/${id}`,
    offerable: (id: Id) => `/rest/consents/offerable?person_id=${id}`,
  },
  embeddable_views: {
    index: `/rest/embeddable_views/`,
  },
  pushDevices: {
    index: `/rest/push_devices`,
  },
  treatment_programs: {
    index: () => `/rest/all_treatment_programs`,
    stubs: (domains: string[] | undefined) =>
      `/rest/treatment_programs/stubs?${stringify(
        { domains },
        {
          indices: false,
          arrayFormat: "brackets",
        },
      )}`,
  },
  treatment_program: {
    index: (programId: Id) => `/rest/treatment_programs/${programId}`,
    addStaff: (programId: Id) => `/rest/treatment_programs/${programId}/staff/`,
    removeStaff: (programId: Id, staffId: Id) =>
      `/rest/treatment_programs/${programId}/staff/${staffId}`,
    addTeam: (programId: Id) =>
      `/rest/treatment_programs/${programId}/hcp_teams`,
    removeTeam: (programId: Id, teamId: Id) =>
      `/rest/treatment_programs/${programId}/hcp_teams/${teamId}`,
    dataInsights: {
      demographics: (programId: Id, filters: Filters) =>
        `/rest/data_insights/demographics?program_id=${programId}&${stringify(filters)}`,
      formAnswerInsights: (programId: Id, filters: Filters) =>
        `/rest/data_insights/form_answers?program_id=${programId}&${stringify(filters)}`,
      messages: (programId: Id, filters: Filters) =>
        `/rest/data_insights/messages?program_id=${programId}&${stringify(filters)}`,
    },
  },
  client_treatment_programs: {
    index: (clientId: Id) => `/rest/clients/${clientId}/treatment_programs`,
    show: (clientId: Id, treatmentProgramId: Id) =>
      `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}`,
    create: (clientId: Id) => `/rest/clients/${clientId}/treatment_programs`,
    destroy: (clientId: Id, treatmentProgramId: Id) =>
      `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}`,
    update_treatment_programs: (clientId: Id) =>
      `/rest/clients/${clientId}/update_treatment_programs`,
    tasks: {
      index: (clientId: Id, treatmentProgramId: Id) =>
        `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}/tasks`,
      create: (clientId: Id, treatmentProgramId: Id) =>
        `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}/tasks`,
      update: (clientId: Id, treatmentProgramId: Id, task_id: Id) =>
        `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}/tasks/${task_id}`,
      destroy: (clientId: Id, treatmentProgramId: Id, task_id: Id) =>
        `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}/tasks/${task_id}`,
    },
  },
  patient_records: {
    index: "/rest/patient_records",
    display: (id: Id) => `/rest/patient_records/${id}/display`,
    download: "/rest/exports/patient_records",
    share: `rest/patient_records/share`,
    activities: "rest/patient_records/activities",
  },
  urgentTasks: {
    index: "/rest/urgent_tasks",
  },
  login_authentications: {
    index: "/rest/login_authentications",
    request_code: "/rest/login_authentications/request_code",
    verify_code: "/rest/login_authentications/verify_code",
  },
  session_expiration: "/auth/expiration",
  views: {
    filledForms: (client_id: Id) => `/clients/${client_id}/form_answers`,
    compareAnswers: (client_id: Id, answerIds: Id[]) =>
      `/clients/${client_id}/form_answers/compare?${stringify({ input_form_answer_ids: answerIds }, { arrayFormat: "brackets" })}`,
    clientFollows: (client_id: Id) => `/clients/${client_id}/follows`,
  },
} as const;
