import { Spinner } from "@netmedi/frontend-design-system";
import Page from "common/components/Page";
import {
  ConversationListRegular,
  ConversationListSupport,
} from "common/containers/DataTable";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { RootState } from "store";
import { SiteSettings } from "common/utils/holvikaari";
import { isClient } from "common/utils/user";
import { withErrorBoundary } from "common/utils/errorBoundary";

import { connect, ConnectedProps } from "react-redux";
import { getConversations } from "shared/actions/chat";
import { pageLoadFailed } from "common/utils/api";
import ConversationList from "./ConversationList.lazy";
import { Conversation } from "shared/models/conversation";
import dayjs from "dayjs";
import { isNil } from "lodash";

const mapStateToProps = (state: RootState) => ({
  conversations: state.chat.conversations.all ?? [],
  user: state.user,
});

const mapDispatchToProps = { getConversations };

// Component supposed to only be used on the patient UI side because of redirect
// to the conversation if it exist.
export const ClientConversationList = (
  props: ConnectedProps<typeof connector>,
) => {
  const [isLoading, setIsLoading] = useState(true);
  const { getConversations, user, conversations } = props;

  useEffect(() => {
    if (isClient(user)) {
      const filters = SiteSettings.enable_support_conversations
        ? {}
        : { filter: "ONLY_REGULAR_CONVERSATIONS" };

      getConversations({ ...filters })
        .then(() => setIsLoading(false))
        .catch(pageLoadFailed);
    } else {
      setIsLoading(false);
    }
  }, []);

  const checkRedirect = (conversations: Conversation[]): JSX.Element | null => {
    if (isLoading) {
      return <Spinner />;
    }

    if (!conversations || conversations.length === 0) {
      return null;
    }

    const latestConversation = conversations?.reduce((prev, current) => {
      const allMessagesNull = conversations.every(conversation =>
        isNil(conversation.last_message_at),
      );

      // Return the first conversation if all last_message_at values are null or undefined
      if (allMessagesNull) return conversations[0];

      // Find the conversation with the latest message
      return dayjs(prev.last_message_at ?? 0).diff(
        dayjs(current.last_message_at ?? 0),
      ) > 0
        ? prev
        : current;
    });

    return conversations?.length > 0 ? (
      <Redirect to={`/conversations/${latestConversation.id}`} />
    ) : null;
  };

  return isClient(user) ? (
    checkRedirect(conversations)
  ) : (
    <Page>
      <ConversationList
        ListComponent={ConversationListRegular}
        title={<FormattedMessage id="conversations.my_conversations" />}
        user={user}
      />

      {SiteSettings.enable_support_conversations && (
        <ConversationList
          ListComponent={ConversationListSupport}
          user={user}
          enableSearch={false}
          title={
            <FormattedMessage
              id="conversations.kaiku_development"
              values={{ product_name: SiteSettings.product_name }}
            />
          }
        >
          <FormattedMessage
            tagName="div"
            id="conversations.conversation_with_admin_info"
            values={{ product_name: SiteSettings.product_name }}
          />
        </ConversationList>
      )}
    </Page>
  );
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withErrorBoundary(connector(ClientConversationList));
