import { loadLocale, flattenMessages } from "common/utils/intl";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import minMax from "dayjs/plugin/minMax";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/tl-ph";
import "dayjs/locale/zh-cn";
import "dayjs/locale/en-au";
import "dayjs/locale/en";
import "dayjs/locale/en-gb";

import { setupBugsnag } from "../../bugsnag";
import { isNil } from "lodash";
import { ActionsObservable, ofType } from "redux-observable";
import { filter, mergeMap, map } from "rxjs/operators";
import { intlActions } from "shared/reducers/intl";
import { SetUserAction } from "common/actions/user";
import { User } from "common/models/user";

// setup dayjs extensions
dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(isBetween);

dayjs.locale("en-gb"); //Default locale set for tests

const LANG_FALLBACKS: any = {
  en: "en-gb",
  "zh-hant": "zh-cn",
  "zh-hans": "zh-cn",
  tl: "tl-ph",
  "en-us": "en",
  "en-ca": "en",
  "en-MY": "en",
  "es-419": "es-mx",
};

export const setLocale = (action$: ActionsObservable<SetUserAction>) =>
  action$.pipe(
    ofType("SET_USER"),
    map(action => action.data),
    filter((data): data is Pick<User, "language"> => !isNil(data.language)),
    mergeMap(data =>
      loadLocale(data.language).then(localeData => {
        if (LANG_FALLBACKS[data.language.toLowerCase()]) {
          dayjs.locale(LANG_FALLBACKS[data.language.toLowerCase()]);
        } else {
          import(`dayjs/locale/${data.language.toLowerCase()}`).then(() => {
            dayjs.locale(data.language.toLowerCase());
          });
        }

        setupBugsnag(data);
        return intlActions.set({
          locale: data.language === "en" ? "en-GB" : data.language,
          messages: flattenMessages(localeData),
        });
      }),
    ),
  );
