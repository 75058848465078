import {
  PatientDemographics,
  FormAnswerInsight,
  MessageInsight,
} from "hcp/models/dataInsights";
import { DataInsightsAction } from "hcp/actions/dataInsights";

const initialState: {
  readonly patientDemographics: PatientDemographics[];
  readonly pastPatientDemographics: PatientDemographics[];
  readonly formAnswerInsights: FormAnswerInsight[];
  readonly messagesInsights: MessageInsight[];
} = {
  patientDemographics: [],
  pastPatientDemographics: [],
  formAnswerInsights: [],
  messagesInsights: [],
};

export type DataInsightsState = typeof initialState;

export default (
  state = initialState,
  action: DataInsightsAction,
): DataInsightsState => {
  switch (action.type) {
    case "GET_PROGRAM_DEMOGRAPHICS":
      return {
        ...state,
        patientDemographics: action.data.filter(p => !p.subscription_end_date),
        pastPatientDemographics: action.data.filter(
          p => p.subscription_end_date,
        ),
      };
    case "GET_PROGRAM_FORM_ANSWER_INSIGHTS":
      return {
        ...state,
        formAnswerInsights: action.data,
      };
    case "GET_PROGRAM_MESSAGES":
      return {
        ...state,
        messagesInsights: action.data,
      };
    default:
      return state;
  }
};
